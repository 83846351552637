///////////////////////////////////////////////////////////
//                           _                           //
//                        _ooOoo_                        //
//                       o8888888o                       //
//                       88" . "88                       //
//                       (| -_- |)                       //
//                       O\  =  /O                       //
//                    ____/`---'\____                    //
//                  .'  \\|     |//  `.                  //
//                 /  \\|||  :  |||//  \                 //
//                /  _||||| -:- |||||_  \                //
//                |   | \\\  -  /'| |   |                //
//                | \_|  `\`---'//  |_/ |                //
//                \  .-\__ `-. -'__/-.  /                //
//              ___`. .'  /--.--\  `. .'___              //
//           ."" '<  `.___\_<|>_/___.' _> \"".           //
//          | | :  `- \`. ;`. _/; .'/ /  .' ; |          //
//          \  \ `-.   \_\_`. _.'_/_/  -' _.' /          //
//===========`-.`___`-.__\ \___  /__.-'_.'_.-'===========//
//
//
//
//
//                      _.-/`)
//                     // / / )
//                  .=// / / / )
//                 //`/ / / / /
//                // /     ` /
//               ||         /
//                \\       /
//                 ))    .'
//                //    /
//                     /

import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, Route } from 'react-router-dom';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import { QueryParamProvider } from 'use-query-params';
import Broken from 'components/Broken';
import { AppContextProvider } from 'components/AppContext';
import { ToastContextProvider } from 'components/Toast';
import { ModalContainer, ModalContextProvider } from 'components/ModalContext';
import config from 'configuration';
import App from './components/App';
import 'assets/styles/index.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

Sentry.init({
  dsn: config.sentryDNS,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const queryClient = new QueryClient();

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={(<Broken />)}>
    <QueryClientProvider client={queryClient}>
      <AppContextProvider>
        <ToastContextProvider>
          <ModalContextProvider>
            <BrowserRouter>
              <QueryParamProvider ReactRouterRoute={Route}>
                <App />
                <ModalContainer />
              </QueryParamProvider>
            </BrowserRouter>
          </ModalContextProvider>
        </ToastContextProvider>
      </AppContextProvider>
    </QueryClientProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root'),
);
