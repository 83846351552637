import React, { useContext, useMemo, useState, useCallback } from 'react';
import { ModalKey } from 'constants/modal';
import DeleteConfirmation from 'components/Modals/DeleteConfirmation';
import EditAdmin from './Modals/EditAdmin';
import EditUser from './Modals/EditUser';
import TransactionDetails from './Modals/TransactionDetails';
import EditRoom from './Modals/EditRoom';
import EditCategory from './Modals/EditCategory';
import EditItem from './Modals/EditItem';
import AddItemsToCategory from './Modals/AddItemsToCategory';
import ImageFurnitureItems from './Modals/ImageFurnitureItems';
import Refund from './Modals/Refund';
import EditDesigner from './Modals/EditDesigner';
import EditTenantStatus from './Modals/EditTenantStatus';
import EditTenantInfo from './Modals/EditTenantInfo';
import TopUpTenant from './Modals/TopUpTenant';
import ChargeAdditionalCost from './Modals/ChargeAdditionalCost';
import RefundFloorPlan from './Modals/RefundFloorPlan';
import FileRequest from './Modals/FileRequest';

const ModalContext = React.createContext();

const MODAL_MAP = {
  [ModalKey.DELETE_CONFIRMATION]: DeleteConfirmation,
  [ModalKey.EDIT_ADMIN]: EditAdmin,
  [ModalKey.EDIT_USER]: EditUser,
  [ModalKey.EDIT_DESIGNER]: EditDesigner,
  [ModalKey.TRANSACTION_DETAILS]: TransactionDetails,
  [ModalKey.EDIT_ROOM]: EditRoom,
  [ModalKey.EDIT_CATEGORY]: EditCategory,
  [ModalKey.EDIT_ITEM]: EditItem,
  [ModalKey.IMAGE_FURNITURE_ITEMS]: ImageFurnitureItems,
  [ModalKey.ADD_ITEMS_TO_CATEGORY]: AddItemsToCategory,
  [ModalKey.REFUND]: Refund,
  [ModalKey.EDIT_TENANT_STATUS]: EditTenantStatus,
  [ModalKey.EDIT_TENANT_INFO]: EditTenantInfo,
  [ModalKey.TOP_UP_TENANT]: TopUpTenant,
  [ModalKey.CHARGE_ADDITIONAL_COST]: ChargeAdditionalCost,
  [ModalKey.REFUND_FLOOR_PLAN]: RefundFloorPlan,
  [ModalKey.FILE_REQUEST]: FileRequest,
};

export function ModalContextProvider({
  children,
}) {
  const [modal, setModal] = useState(null);

  const showModal = useCallback((key, props) => {
    setModal({
      key,
      props,
    });
  }, [setModal]);

  const contextValue = useMemo(() => ({
    modal,
    showModal,
    setModal,
  }), [
    modal,
    showModal,
    setModal,
  ]);

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
}

export function useModalContext() {
  return useContext(ModalContext);
}

export function ModalContainer() {
  const { modal, setModal } = useModalContext();

  const onClose = useCallback((...params) => {
    modal.props?.onClose?.(...params);
    setModal(null);
  }, [modal, setModal]);

  const ModalComponent = MODAL_MAP[modal?.key];

  if (!ModalComponent) {
    return null;
  }

  return (
    <ModalComponent
      {...modal.props}
      onClose={onClose}
    />
  );
}
