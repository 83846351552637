import React, { useState, useMemo } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Modal, Button, Form } from 'react-bootstrap';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useToastContext } from 'components/Toast';
import { useAppContext } from 'components/AppContext';
import * as api from 'utils/api';

const DEFAULT_REFUND_EMAIL_CONTENT = `<div>
We have successfully sent you a refund for unsuccessful design. Thank you for using our services.
</div>`;

const getEmailPreview = (emailContent) => `<div>
Hi John,
</div>
<br>
${emailContent}
<br>
<div>
Best regards.
</div>
<div>
Auto Design Team
</div>`;

const getEmailTemplate = (emailContent) => `<div>
Hi {{username}},
</div>
<br>
${emailContent}
<br>
<div>
Best regards.
</div>
<div>
Auto Design Team
</div>`;

export default function RefundFloorPlan({
  onClose,
  onDone,
  floorPlanId,
}) {
  const { request } = useAppContext();
  const { toastError } = useToastContext();
  const [submitting, setSubmitting] = useState(false);
  const [reason, setReason] = useState('');
  const [bonus, setBonus] = useState(0);
  const [editorState, setEditorState] = useState(() => {
    const contentBlock = htmlToDraft(DEFAULT_REFUND_EMAIL_CONTENT);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      return EditorState.createWithContent(contentState);
    }

    return null;
  },
  );

  const html = useMemo(() => draftToHtml(convertToRaw(editorState.getCurrentContent())), [editorState]);

  const refund = async () => {
    setSubmitting(true);

    const { success, error } = await request(api.refundFloorPlan(floorPlanId, {
      reason,
      emailTemplate: getEmailTemplate(html),
      bonus: Number.isNaN(parseInt(bonus)) ? 0 : parseInt(bonus),
    }));

    if (success) {
      onDone();
      setSubmitting(false);
      onClose();
    } else {
      toastError(error);
      setSubmitting(false);
    }
  };

  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      id="modal-refund"
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>Refund</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="form-group">
          <Form.Label>Refund reason (Internal purpose)</Form.Label>
          <Form.Control
            as="textarea"
            value={reason}
            onChange={e => setReason(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>Bonus coins (beside refunded amount)</Form.Label>
          <Form.Control
            value={bonus}
            type="number"
            step={1}
            onChange={e => setBonus(e.target.value)}
          />
        </Form.Group>
        <Form.Group
          className="form-group"
          style={{
            marginTop: 16,
          }}
        >
          <Form.Label>Refund Email (Send to customer)</Form.Label>
          <div style={{
            border: '1px solid #ccc',
          }}
          >
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(state) => setEditorState(state)}
            />
          </div>
        </Form.Group>
        <Form.Group
          className="form-group"
          style={{
            marginTop: 16,
          }}
        >
          <Form.Label>Email Preview</Form.Label>
          <div
            dangerouslySetInnerHTML={{
              __html: getEmailPreview(html),
            }}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={submitting}
          variant="danger"
          onClick={refund}
        >
          Refund
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
