import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useDebounce } from 'use-debounce';
import { useHistory } from 'react-router-dom';
import { useQueryParam, NumberParam, DateParam } from 'use-query-params';
import { Form, Table, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Sidebar from 'components/Common/Sidebar';
import { useToastContext, Toast } from 'components/Toast';
import { useModalContext } from 'components/ModalContext';
import { useAppContext } from 'components/AppContext';
import { getLocaleDateString } from 'utils/time';
import * as api from 'utils/api';
import CustomPagination from 'components/Common/CustomPagination';
import { ModalKey } from 'constants/modal';
import ImageStatus from 'components/Common/ImageStatus';
import { ImageType, ImageTypeNameMap } from 'constants/common';

const ITEMS_PER_PAGE = 10;

const today = moment().endOf('day').toDate();
const a30daysAgo = moment().startOf('day').subtract(30, 'd').toDate();

export default function Images() {
  const history = useHistory();
  const { showModal } = useModalContext();
  const { request } = useAppContext();
  const { toastError } = useToastContext();
  const [images, setImages] = useState([]);
  const [start = a30daysAgo, setStart] = useQueryParam('start', DateParam);
  const [end = today, setEnd] = useQueryParam('end', DateParam);
  const [page = 1, setPage] = useQueryParam('page', NumberParam);
  const [userEmail = '', setUserEmail] = useQueryParam('userEmail');
  const [status = 'all', setStatus] = useQueryParam('status');
  const [type = 'all', setType] = useQueryParam('type');
  const [numPages, setNumPages] = useState(0);

  const [debouncedUserEmail] = useDebounce(userEmail, 500, {
    leading: true,
    trailing: true,
  });

  const startTS = start.getTime();
  const endTS = end.getTime();

  const fetchImages = useCallback(async () => {
    const { success, error, result } = await request(api.getImages({
      start: startTS,
      end: endTS,
      limit: ITEMS_PER_PAGE,
      offset: (page - 1) * ITEMS_PER_PAGE,
      status: status === 'all' ? undefined : status,
      type: type === 'all' ? undefined : type,
      userEmail: debouncedUserEmail.trim(),
    }));

    if (success) {
      setImages(result.images);
      setNumPages(Math.ceil(result.count / ITEMS_PER_PAGE));
    } else {
      toastError(error);
    }
  }, [startTS, endTS, page, status, request, toastError, debouncedUserEmail, type]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  return (
    <div className="main images">
      <Sidebar />
      <div className="right-part">
        <Toast />
        <div className="title">
          Images
        </div>
        <div className="filters">
          <Form.Group>
            <Form.Label>Start Date</Form.Label>
            <DatePicker
              selected={start}
              onChange={(date) => setStart(date)}
              dateFormat={getLocaleDateString().replaceAll('D', 'd')}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>End Date</Form.Label>
            <DatePicker
              selected={end}
              onChange={(date) => setEnd(date)}
              dateFormat={getLocaleDateString().replaceAll('D', 'd')}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>User Email</Form.Label>
            <Form.Control
              value={userEmail || ''}
              type="text"
              onChange={e => setUserEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Type</Form.Label>
            <Form.Select
              value={type}
              onChange={e => {
                setType(e.target.value);
              }}
            >
              <option value="all">All</option>
              {[ImageType.NORMAL, ImageType.PANO, ImageType.FLOOR_PLAN].map(type => (
                <option key={type} value={type}>{ImageTypeNameMap[type]}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label>Status</Form.Label>
            <Form.Select
              value={status}
              onChange={e => {
                setStatus(e.target.value);
              }}
            >
              <option value="all">All</option>
              <option value="created">Created</option>
              <option value="ready">Ready</option>
              <option value="failed">Failed</option>
              <option value="refunded">Refunded</option>
            </Form.Select>
          </Form.Group>
        </div>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Type</th>
              <th>Location</th>
              <th>User Email</th>
              <th>Status</th>
              <th>Auto Staging</th>
              <th>Renders</th>
              <th>Created At</th>
              <th>Frame Created At</th>
              <th>Updated At</th>
              <th>{' '}</th>
            </tr>
          </thead>
          <tbody>
            {images.map(image => (
              <tr key={image.id}>
                <td>
                  <div
                    className="image-id"
                    onClick={() => {
                      history.push(`/images/${image.id}`);
                    }}
                  >
                    {image.id}
                  </div>
                </td>
                <td>
                  {image.name}
                </td>
                <td>
                  {ImageTypeNameMap[image.type]}
                </td>
                <td>{image.location?.name}</td>
                <td>
                  <div
                    className="image-id"
                    onClick={() => {
                      window.open(`${window.origin}/users?search=${image.user.email}`);
                    }}
                  >
                    {image.user.email}
                  </div>
                </td>
                <td>
                  <ImageStatus status={image.status} />
                </td>
                <td>
                  {image.metadata.autoStaging ? 'Yes' : 'No'}
                </td>
                <td>
                  {image.renders}
                </td>
                <td>
                  {moment.utc(image.createdAt).local().format(`${getLocaleDateString()} HH:mm`)}
                </td>
                <td>
                  {moment.utc(image.frameCreatedAt).local().format(`${getLocaleDateString()} HH:mm`)}
                </td>
                <td>
                  {moment.utc(image.updatedAt).local().format(`${getLocaleDateString()} HH:mm`)}
                </td>
                <td>
                  <div className="actions">
                    {image.type !== ImageType.FLOOR_PLAN && image.status !== 'refunded' && (
                      <Button
                        variant="danger"
                        onClick={() => showModal(ModalKey.REFUND, {
                          onDone: fetchImages,
                          imageId: image.id,
                        })}
                      >
                        Refund
                      </Button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="pagination-wrapper">
          <CustomPagination
            page={page}
            setPage={setPage}
            numPages={numPages}
          />
        </div>
      </div>
    </div>
  );
}
