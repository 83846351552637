import { post, get, put, del } from 'utils/request';

export const login = (data) => post('/admin/auth/login', data);

export const getInfo = () => get('/admin/me/info');

export const getAdmins = () => get('/admin/admins');

export const getAdmin = (id) => get(`/admin/admins/${id}`);

export const editAdmin = (id, data) => put(`/admin/admins/${id}`, data);

export const deleteAdmin = (id) => del(`/admin/admins/${id}`);

export const addAdmin = (data) => post('/admin/admins/', data);

export const getDesigners = () => get('/admin/designers');

export const getDesigner = (id) => get(`/admin/designers/${id}`);

export const editDesigner = (id, data) => put(`/admin/designers/${id}`, data);

export const deleteDesigner = (id) => del(`/admin/designers/${id}`);

export const addDesigner = (data) => post('/admin/designers/', data);

export const getUsers = (params) => get('/admin/users', params);

export const getSuspiciousUsers = params => get('/admin/users/suspicious-users', params);

export const getUser = (id) => get(`/admin/users/${id}`);

export const editUser = (id, data) => put(`/admin/users/${id}`, data);

export const deleteUser = (id) => del(`/admin/users/${id}`);

export const addUser = (data) => post('/admin/users/', data);

export const exportUsers = (params) => post('/admin/users/exports', params);

export const getTransactions = (params) => get('/admin/transactions', params);

export const getTransaction = (id) => get(`/admin/transactions/${id}`);

export const getRooms = () => get('/admin/furniture/rooms');

export const getNestedRooms = () => get('/admin/furniture/rooms?nested=true');

export const getRoom = (id) => get(`/admin/furniture/rooms/${id}`);

export const deleteRoom = id => del(`/admin/furniture/rooms/${id}`);

export const getTenants = (params) => get('/admin/tenants', params);

export const getTenant = (id) => get(`/admin/tenants/${id}`);

export const editTenant = (id, data) => put(`/admin/tenants/${id}`, data);

export const addTenant = (data) => post('/admin/tenants/', data);

export const editStatusTenant = (id, payload) => put(`/admin/tenants/${id}`, payload);

export const topUpTenant = (id, payload) => post(`/admin/tenants/${id}/transactions`, payload);

export const editRoom = (id, data) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (key === 'thumbnail') {
      if (data[key] instanceof File) {
        formData.append(key, data[key], data[key].name);
      }
    } else if (data[key] instanceof Object) {
      formData.append(key, JSON.stringify(data[key]));
    } else if (data[key] !== undefined) {
      formData.append(key, data[key]);
    }
  });

  return put(`/admin/furniture/rooms/${id}`, formData, {
    'Content-Type': 'multipart/form-data',
  });
};

export const addRoom = (data) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (key === 'thumbnail') {
      if (data[key] instanceof File) {
        formData.append(key, data[key], data[key].name);
      }
    } else if (data[key] instanceof Object) {
      formData.append(key, JSON.stringify(data[key]));
    } else if (data[key] !== undefined) {
      formData.append(key, data[key]);
    }
  });

  return post('/admin/furniture/rooms', formData, {
    'Content-Type': 'multipart/form-data',
  });
};

export const moveRoomUp = (id) => post(`/admin/furniture/rooms/${id}/move-up`);

export const moveRoomDown = (id) => post(`/admin/furniture/rooms/${id}/move-down`);

export const addItemsToRoom = (id, itemIds) => post(`/admin/furniture/rooms/${id}/items`, {
  ids: itemIds,
});

export const getCategories = () => get('/admin/furniture/categories');

export const getCategory = (id) => get(`/admin/furniture/categories/${id}`);

export const deleteCategory = id => del(`/admin/furniture/categories/${id}`);

export const editCategory = (id, data) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (key === 'thumbnail') {
      if (data[key] instanceof File) {
        formData.append(key, data[key], data[key].name);
      }
    } else if (data[key] instanceof Object) {
      formData.append(key, JSON.stringify(data[key]));
    } else if (data[key] !== undefined) {
      formData.append(key, data[key]);
    }
  });

  return put(`/admin/furniture/categories/${id}`, formData, {
    'Content-Type': 'multipart/form-data',
  });
};

export const addCategory = (data) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (key === 'thumbnail') {
      if (data[key] instanceof File) {
        formData.append(key, data[key], data[key].name);
      }
    } else if (data[key] instanceof Object) {
      formData.append(key, JSON.stringify(data[key]));
    } else if (data[key] !== undefined) {
      formData.append(key, data[key]);
    }
  });

  return post('/admin/furniture/categories', formData, {
    'Content-Type': 'multipart/form-data',
  });
};

export const moveCategoryUp = (id) => post(`/admin/furniture/categories/${id}/move-up`);

export const moveCategoryDown = (id) => post(`/admin/furniture/categories/${id}/move-down`);

export const addItemsToCategory = (id, itemIds) => post(`/admin/furniture/categories/${id}/items`, {
  ids: itemIds,
});

export const getItems = (params) => get('/admin/furniture/items', params);

export const getItem = (id) => get(`/admin/furniture/items/${id}`);

export const editItem = (id, data) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (data[key] instanceof File) {
      if (data[key] instanceof File) {
        formData.append(key, data[key], data[key].name);
      }
    } else if (data[key] instanceof Object) {
      formData.append(key, JSON.stringify(data[key]));
    } else if (data[key] !== undefined) {
      formData.append(key, data[key]);
    }
  });

  return put(`/admin/furniture/items/${id}`, formData, {
    'Content-Type': 'multipart/form-data',
  });
};

export const deleteItem = (id) => del(`/admin/furniture/items/${id}`);

export const addItem = (data) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (data[key] instanceof File) {
      if (data[key] instanceof File) {
        formData.append(key, data[key], data[key].name);
      }
    } else if (data[key] instanceof Object) {
      formData.append(key, JSON.stringify(data[key]));
    } else if (data[key] !== undefined) {
      formData.append(key, data[key]);
    }
  });

  return post('/admin/furniture/items', formData, {
    'Content-Type': 'multipart/form-data',
  });
};

export const getImages = params => get('/admin/images', params);

export const refundImage = (id, data) => post(`/admin/images/${id}/refund`, data);

export const getImage = id => get(`/admin/images/${id}`);

export const uploadFrame = (id, projectFile, frameFile) => {
  const formData = new FormData();

  formData.append('project', projectFile, projectFile.name);

  if (frameFile) {
    formData.append('frame', frameFile, frameFile.name);
  }

  return post(`/admin/images/${id}/frames`, formData, {
    'Content-Type': 'multipart/form-data',
  });
};

export const getConfigs = () => get('/admin/configs');

export const updateConfig = (id, value) => put(`/admin/configs/${id}`, { value });

export const uploadFile = ({
  file,
  isPublic,
}) => {
  const formData = new FormData();
  formData.append('file', file, file.name);
  formData.append('public', isPublic);

  return post('/admin/files', formData, {
    'Content-Type': 'multipart/form-data',
  });
};

export const getExport = (id) => get(`/admin/exports/${id}`);

export const getRenderProgresses = (params) => get('/admin/progresses', {
  ...params,
  type: 'render',
});

export const chargeAdditionalCost = (floorPlanId, data) => post(`/admin/floor-plans/${floorPlanId}/charges`, data);

export const getFloorPlans = (data) => get('/admin/floor-plans', data);

export const getFloorPlanDetails = (id) => get(`/admin/floor-plans/${id}`);

export const refundFloorPlan = (id, data) => post(`/admin/floor-plans/${id}/refund`, data);

export const getFloorPlanLogs = (id) => get(`/admin/floor-plans/${id}/logs`);

export const getFloorPlanFiles = (id) => get(`/admin/floor-plans/${id}/floor-plan-files`);

export const requestFiles = (id, data) => post(`/admin/floor-plans/${id}/file-requests`, data);

export const updateFloorPlan = (id, data) => put(`/admin/floor-plans/${id}`, data);
