import React, { useMemo } from 'react';
import moment from 'moment';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import * as api from 'utils/api';
import classNames from 'classnames';
import Sidebar from 'components/Common/Sidebar';
import { Breadcrumb, Button, Table } from 'react-bootstrap';
import ImageStatus from 'components/Common/ImageStatus';
import { getLocaleDateString } from 'utils/time';
import { ModalKey } from 'constants/modal';
import { useModalContext } from 'components/ModalContext';
import { getFileUrl } from 'utils/file';
import { FloorPlanFileSource, FloorPlanStatus } from 'constants/common';
import { useAppContext } from 'components/AppContext';
import styles from './FloorPlanDetails.module.scss';
import DesignerNotes from './DesignerNotes';

const LogNameMap = {
  designer_claim: 'Designer claims',
  designer_upload_project_file: 'Designer uploads Blender file',
  designer_upload_frame_file: 'Designer uploads GLB file',
  designer_render_3d_overview: 'Designer renders 3d overview',
  designer_render_3d_overview_done: '3d overview is ready',
  designer_upload_2d_overview_file: 'Designer uploads 2d overview file',
  designer_upload_2d_background_file: 'Designer uploads 2d background file',
  designer_submit: 'Designer submits',
  user_submit_change_request: 'User submits change request',
  admin_charge: 'Admin charges',
  user_request_refund: 'User requests refund',
  user_pay_extra_cost: 'User pays extra cost',
  user_purchase_packages: 'User purchases packages',
  admin_request_files: 'Admin request files',
  user_end_file_request: 'User ends file request',
  user_finish_upload: 'User finishes uploading',
};

function Log({ log }) {
  const renderMetadata = (title, field) => (
    <div className={styles.metadataField}>
      <div className={styles.metadataFieldTitle}>
        {title}
      </div>
      <div className={styles.metadataFieldContent}>
        {log.metadata[field]}
      </div>
    </div>
  );

  return (
    <div className={styles.log}>
      <div className={styles.time}>
        {moment.utc(log.createdAt).local().format('YYYY-MM-DD HH:mm')}
      </div>
      <div className={styles.content}>
        {LogNameMap[log.name]}
      </div>
      {log.name === 'admin_charge' && (
        <div>
          {renderMetadata('Coins', 'coins')}
          {renderMetadata('Reason', 'reason')}
        </div>
      )}
      {log.name === 'user_submit_change_request' && (
        <div>
          {renderMetadata('Request', 'changes')}
        </div>
      )}
      {log.name === 'user_purchase_packages' && (
        <div>
          <div className={styles.metadataField}>
            <div className={styles.metadataFieldTitle}>
              Packages
            </div>
            <div className={styles.metadataFieldContent}>
              {log.metadata.packages.map(p => (<div key={p}>{p}</div>))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default function FloorPlanDetails() {
  const history = useHistory();
  const { floorPlanId: floorPlanIdStr } = useParams();
  const floorPlanId = parseInt(floorPlanIdStr);
  const { request } = useAppContext();
  const { data: floorPlan, refetch } = useQuery({
    queryKey: ['floor-plan', floorPlanId],
    queryFn: () => api.getFloorPlanDetails(floorPlanId),
  });
  const { data: logsData, refetch: refetchLogs } = useQuery({
    queryKey: ['floor-plan', floorPlanId, 'logs'],
    queryFn: () => api.getFloorPlanLogs(floorPlanId),
  });

  const { data: floorPlanFiles } = useQuery({
    queryKey: ['floor-plan', floorPlanId, 'floor-plan-files'],
    queryFn: () => api.getFloorPlanFiles(floorPlanId),
  });

  const { showModal } = useModalContext();

  const logs = useMemo(() => logsData?.logs || [], [logsData]);

  if (!floorPlan) {
    return null;
  }

  return (
    <div className={classNames('main', styles.floorPlanDetails)}>
      <Sidebar />
      <div className="right-part">
        <div className="title">
          <Breadcrumb>
            <Breadcrumb.Item
              active={false}
              onClick={() => {
                history.push('/floor-plans');
              }}
            >
              Floor Plans
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {floorPlanId}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <h5>
              Details
            </h5>
            <Table>
              <tbody>
                <tr>
                  <td>
                    ID
                  </td>
                  <td>
                    {floorPlanId}
                  </td>
                </tr>
                <tr>
                  <td>
                    Name
                  </td>
                  <td>
                    {floorPlan.name}
                  </td>
                </tr>
                <tr>
                  <td>
                    User ID
                  </td>
                  <td>
                    {floorPlan.userId}
                  </td>
                </tr>
                <tr>
                  <td>
                    Status
                  </td>
                  <td>
                    <ImageStatus status={floorPlan.status} />
                  </td>
                </tr>
                <tr>
                  <td>
                    Packages
                  </td>
                  <td>
                    {floorPlan.packages.map(p => (
                      <div>
                        {p}
                      </div>
                    ))}
                  </td>
                </tr>
                <tr>
                  <td>
                    User notes
                  </td>
                  <td style={{
                    whiteSpace: 'pre-line',
                  }}
                  >
                    {floorPlan.userNotes}
                  </td>
                </tr>
                <tr>
                  <td>
                    Designer notes
                  </td>
                  <td>
                    <DesignerNotes
                      designerNotes={floorPlan.designerNotes}
                      onSave={async (value) => {
                        await request(api.updateFloorPlan(floorPlanId, {
                          designerNotes: value,
                        }));
                        refetch();
                      }}
                    />
                  </td>
                </tr>
                {floorPlan.metadata?.error && (
                  <tr>
                    <td>
                      Error
                    </td>
                    <td>
                      <div style={{
                        whiteSpace: 'pre-wrap',
                      }}
                      >
                        {floorPlan.metadata?.error}
                      </div>
                    </td>
                  </tr>
                )}
                <tr>
                  <td>
                    Created At
                  </td>
                  <td>
                    {moment.utc(floorPlan.createdAt).local().format(`${getLocaleDateString()} HH:mm`)}
                  </td>
                </tr>
                <tr>
                  <td>
                    Updated At
                  </td>
                  <td>
                    {moment.utc(floorPlan.updatedAt).local().format(`${getLocaleDateString()} HH:mm`)}
                  </td>
                </tr>
                <tr>
                  <td>Actions</td>
                  <td className={styles.actionButtons}>
                    {floorPlan.status !== FloorPlanStatus.REFUNDED && (
                      <Button
                        variant="danger"
                        onClick={() => showModal(ModalKey.REFUND_FLOOR_PLAN, {
                          onDone: refetch,
                          floorPlanId,
                        })}
                      >
                        Refund
                      </Button>
                    )}

                    {floorPlan.status !== FloorPlanStatus.EXTRA_COST_APPROVAL && (
                      <Button
                        onClick={() => showModal(ModalKey.CHARGE_ADDITIONAL_COST, {
                          onDone: () => {
                            refetchLogs();
                            refetch();
                          },
                          floorPlanId,
                        })}
                      >
                        Charge additional cost
                      </Button>
                    )}

                    {floorPlan.status !== FloorPlanStatus.REQUEST_FILES && (
                      <Button
                        onClick={() => showModal(ModalKey.FILE_REQUEST, {
                          onDone: () => {
                            refetchLogs();
                            refetch();
                          },
                          floorPlanId,
                        })}
                      >
                        Request files
                      </Button>
                    )}

                  </td>
                </tr>
              </tbody>
            </Table>
            <h5>
              Files
            </h5>
            <Table>
              <thead>
                <tr>
                  <th>
                    Type
                  </th>
                  <th>
                    File
                  </th>
                </tr>
              </thead>
              <tbody>
                {floorPlanFiles && floorPlanFiles.map(f => (
                  <tr key={f.id}>
                    <td>
                      {f.type || 'N/A'}
                      {' '}
                      {f.source === FloorPlanFileSource.VIDEO && '(Video)'}
                      {f.source === FloorPlanFileSource.CAD && '(CAD)'}
                    </td>
                    <td>
                      {f.type === 'url' ? (
                        <a
                          href={f.fileUrl}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            wordBreak: 'break-all',
                          }}
                        >
                          {f.fileUrl}
                        </a>
                      ) : (
                        <a
                          target="_blank"
                          href={getFileUrl(f.fileId)}
                          rel="noreferrer"
                        >
                          {f.name}
                        </a>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

          </div>
          <div className="col-lg-4">
            <h5>Units</h5>
            <div>
              {floorPlan.units.map(unit => (
                <div className={styles.unit}>
                  <div className={styles.name}>{unit.name}</div>
                  <Table>
                    <tbody>
                      <tr>
                        <td>
                          Blender file
                        </td>
                        <td>
                          {unit.image?.projectFileId ? (
                            <a
                              target="__blank"
                              href={getFileUrl(unit.image?.projectFileId)}
                            >
                              Download
                            </a>
                          ) : 'N/A'}

                        </td>
                      </tr>
                      <tr>
                        <td>
                          GLB file
                        </td>
                        <td>
                          {unit.image?.frameFileId ? (
                            <a
                              target="__blank"
                              href={getFileUrl(unit.image?.frameFileId)}
                            >
                              Download
                            </a>
                          ) : 'N/A'}

                        </td>
                      </tr>
                      <tr>
                        <td>
                          2D background file
                        </td>
                        <td>
                          {unit.background_2dFileId ? (
                            <a
                              target="__blank"
                              href={getFileUrl(unit.background_2dFileId)}
                            >
                              Download
                            </a>
                          ) : 'N/A'}

                        </td>
                      </tr>
                      <tr>
                        <td>
                          3D output file
                        </td>
                        <td>
                          {unit.output_3dFileId ? (
                            <a
                              target="__blank"
                              href={getFileUrl(unit.output_3dFileId)}
                            >
                              Download
                            </a>
                          ) : 'N/A'}

                        </td>
                      </tr>
                      <tr>
                        <td>
                          2D output file
                        </td>
                        <td>
                          {unit.output_2dFileId ? (
                            <a
                              target="__blank"
                              href={getFileUrl(unit.output_2dFileId)}
                            >
                              Download
                            </a>
                          ) : 'N/A'}

                        </td>
                      </tr>
                      <tr>
                        <td>
                          3D Image
                        </td>
                        <td>
                          {unit.imageId ? (
                            <a
                              onClick={() => {
                                history.push(`/images/${unit.imageId}`);
                              }}
                              href=""
                            >
                              Open
                            </a>
                          ) : 'N/A'}

                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-4">
            <h5>
              Floor plan activities
            </h5>
            {logs.map(log => (
              <Log key={log.id} log={log} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
