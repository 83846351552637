import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useQueryParam, NumberParam, StringParam, DateParam } from 'use-query-params';
import { Form, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Sidebar from 'components/Common/Sidebar';
import { useToastContext, Toast } from 'components/Toast';
import { useAppContext } from 'components/AppContext';
import { getLocaleDateString } from 'utils/time';
import * as api from 'utils/api';
import CustomPagination from 'components/Common/CustomPagination';
import config from 'configuration';
import { getToken } from 'utils/auth';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';

const ITEMS_PER_PAGE = 10;

const today = moment().endOf('day').toDate();
const a30daysAgo = moment().startOf('day').subtract(30, 'd').toDate();

export default function RenderProgresses() {
  const { request } = useAppContext();
  const { toastError } = useToastContext();
  const [progresses, setProgresses] = useState([]);
  const [start = a30daysAgo, setStart] = useQueryParam('start', DateParam);
  const [end = today, setEnd] = useQueryParam('end', DateParam);
  const [status = 'all', setStatus] = useQueryParam('status', StringParam);
  const [page = 1, setPage] = useQueryParam('page', NumberParam);
  const [numPages, setNumPages] = useState(0);
  const { showModal } = useModalContext();

  const startTS = start.getTime();
  const endTS = end.getTime();

  useEffect(() => {
    (async () => {
      const { success, error, result } = await request(api.getRenderProgresses({
        start: startTS,
        end: endTS,
        status: status !== 'all' ? status : undefined,
        limit: ITEMS_PER_PAGE,
        offset: (page - 1) * ITEMS_PER_PAGE,
      }));

      if (success) {
        setProgresses(result.progresses);
        setNumPages(Math.ceil(result.count / ITEMS_PER_PAGE));
      } else {
        toastError(error);
      }
    })();
  }, [startTS, endTS, status, page, request, toastError]);

  return (
    <div className="main render-progresses">
      <Sidebar />
      <div className="right-part">
        <Toast />
        <div className="title">
          Render Progresses
        </div>
        <div className="filters">
          <Form.Group>
            <Form.Label>Status</Form.Label>
            <Form.Select
              value={status}
              onChange={e => setStatus(e.target.value)}
            >
              <option value="all">All</option>
              <option value="ready">Ready</option>
              <option value="in_progress">In-Progress</option>
              <option value="failed">Failed</option>
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label>Start Date</Form.Label>
            <DatePicker
              selected={start}
              onChange={(date) => setStart(date)}
              dateFormat={getLocaleDateString().replaceAll('D', 'd')}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>End Date</Form.Label>
            <DatePicker
              selected={end}
              onChange={(date) => setEnd(date)}
              dateFormat={getLocaleDateString().replaceAll('D', 'd')}
            />
          </Form.Group>
        </div>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>User</th>
              <th>Image</th>
              <th>Location</th>
              <th>Status</th>
              <th>Renderer</th>
              <th>Created At</th>
              <th>Done At</th>
              <th>{' '}</th>
            </tr>
          </thead>
          <tbody>
            {progresses.map(progress => (
              <tr key={progress.id}>
                <td>
                  <div>
                    {progress.id}
                  </div>
                </td>
                <td>
                  <div
                    className="link"
                    onClick={() => {
                      window.open(`${window.origin}/users?search=${progress.user.email}`);
                    }}
                  >
                    {progress.user.email}
                  </div>
                </td>
                <td>
                  <div
                    className="link"
                    onClick={() => {
                      window.open(`${window.origin}/images/${progress.image.id}`);
                    }}
                  >
                    {progress.image.name}
                  </div>
                </td>
                <td>
                  <div>
                    {progress.image.location?.name}
                  </div>
                </td>
                <td>{progress.status}</td>
                <td>{progress.metadata.renderer?.name}</td>
                <td>
                  {moment.utc(progress.createdAt).local().format(`${getLocaleDateString()} HH:mm`)}
                </td>
                <td>
                  {moment.utc(progress.doneAt).local().format(`${getLocaleDateString()} HH:mm`)}
                </td>
                <td>
                  <div className="links">
                    {progress.thumbnailFileId && (
                    <div>
                      <img
                        className="output"
                        src={`${config.apiUrl}/files/${progress.thumbnailFileId}?accessToken=${getToken()}`}
                        alt="Output"
                        style={{
                          maxHeight: '200px',
                          maxWidth: '300px',
                        }}
                      />
                      <a
                        href={`${config.apiUrl}/files/${progress.metadata.outputFileId}?accessToken=${getToken()}`}
                        target="__blank"
                      >
                        Download output
                      </a>
                    </div>
                    )}
                    {progress.metadata?.furnitureOutputFileId && (
                    <a
                      href={`${config.apiUrl}/files/${progress.metadata.furnitureOutputFileId}?accessToken=${getToken()}`}
                      target="__blank"
                    >
                      Download furniture layer
                    </a>
                    )}
                    {progress.metadata?.logFileFileId && (
                    <a
                      href={`${config.apiUrl}/files/${progress.metadata.logFileFileId}?accessToken=${getToken()}`}
                      target="__blank"
                    >
                      Download logs
                    </a>
                    )}
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        showModal(ModalKey.IMAGE_FURNITURE_ITEMS, {
                          objects: progress.metadata.objects,
                        });
                      }}
                    >
                      Furniture Items
                    </a>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="pagination-wrapper">
          <CustomPagination
            page={page}
            setPage={setPage}
            numPages={numPages}
          />
        </div>
      </div>
    </div>
  );
}
