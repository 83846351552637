import React, { useMemo } from 'react';
import moment from 'moment';
import { useDebounce } from 'use-debounce';
import { useHistory } from 'react-router-dom';
import { useQueryParam, NumberParam, DateParam } from 'use-query-params';
import { useQuery } from '@tanstack/react-query';
import { Form, Table, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Sidebar from 'components/Common/Sidebar';
import { useModalContext } from 'components/ModalContext';
import { getLocaleDateString } from 'utils/time';
import * as api from 'utils/api';
import CustomPagination from 'components/Common/CustomPagination';
import { ModalKey } from 'constants/modal';
import ImageStatus from 'components/Common/ImageStatus';
import classNames from 'classnames';
import styles from './FloorPlans.module.scss';

const ITEMS_PER_PAGE = 10;

const today = moment().endOf('day').toDate();
const a30daysAgo = moment().startOf('day').subtract(30, 'd').toDate();

export default function FloorPlans() {
  const history = useHistory();
  const { showModal } = useModalContext();
  const [start = a30daysAgo, setStart] = useQueryParam('start', DateParam);
  const [end = today, setEnd] = useQueryParam('end', DateParam);
  const [page = 1, setPage] = useQueryParam('page', NumberParam);
  const [userEmail = '', setUserEmail] = useQueryParam('userEmail');
  const [status = 'all', setStatus] = useQueryParam('status');

  const startTS = start.getTime();
  const endTS = end.getTime();

  const [debouncedUserEmail] = useDebounce(userEmail, 500, {
    leading: true,
    trailing: true,
  });

  const params = useMemo(() => ({
    start: startTS,
    end: endTS,
    limit: ITEMS_PER_PAGE,
    offset: (page - 1) * ITEMS_PER_PAGE,
    status: status === 'all' ? undefined : status,
    userEmail: debouncedUserEmail.trim(),
  }), [debouncedUserEmail, endTS, page, startTS, status]);

  const { data, refetch } = useQuery({
    queryKey: ['floor-plans', params],
    queryFn: () => api.getFloorPlans(params),
  });

  const floorPlans = useMemo(() => data?.floorPlans || [], [data]);
  const numPages = useMemo(() => (data ? Math.ceil(data.count / ITEMS_PER_PAGE) : 0), [data]);

  return (
    <div className={classNames('main', styles.floorPlan)}>
      <Sidebar />
      <div className="right-part">
        <div className="title">
          Floor Plans
        </div>
        <div className="filters">
          <Form.Group>
            <Form.Label>Start Date</Form.Label>
            <DatePicker
              selected={start}
              onChange={(date) => setStart(date)}
              dateFormat={getLocaleDateString().replaceAll('D', 'd')}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>End Date</Form.Label>
            <DatePicker
              selected={end}
              onChange={(date) => setEnd(date)}
              dateFormat={getLocaleDateString().replaceAll('D', 'd')}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>User Email</Form.Label>
            <Form.Control
              value={userEmail || ''}
              type="text"
              onChange={e => setUserEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Status</Form.Label>
            <Form.Select
              value={status}
              onChange={e => {
                setStatus(e.target.value);
              }}
            >
              <option value="all">All</option>
              <option value="created">Created</option>
              <option value="ready">Ready</option>
              <option value="failed">Failed</option>
              <option value="refunded">Refunded</option>
            </Form.Select>
          </Form.Group>
        </div>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>User Email</th>
              <th>Status</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>{' '}</th>
            </tr>
          </thead>
          <tbody>
            {floorPlans.map(floorPlan => (
              <tr key={floorPlan.id}>
                <td>
                  <div
                    className={styles.link}
                    onClick={() => {
                      history.push(`/floor-plans/${floorPlan.id}`);
                    }}
                  >
                    {floorPlan.id}
                  </div>
                </td>
                <td>
                  {floorPlan.name}
                </td>
                <td>
                  <div
                    className={styles.link}
                    onClick={() => {
                      window.open(`${window.origin}/users?search=${floorPlan.user.email}`);
                    }}
                  >
                    {floorPlan.user.email}
                  </div>
                </td>
                <td>
                  <ImageStatus status={floorPlan.status} />
                </td>
                <td>
                  {moment.utc(floorPlan.createdAt).local().format(`${getLocaleDateString()} HH:mm`)}
                </td>
                <td>
                  {moment.utc(floorPlan.updatedAt).local().format(`${getLocaleDateString()} HH:mm`)}
                </td>
                <td>
                  <div className={styles.actions}>
                    {floorPlan.status !== 'refunded' && (
                      <Button
                        variant="danger"
                        onClick={() => showModal(ModalKey.REFUND_FLOOR_PLAN, {
                          onDone: refetch,
                          floorPlanId: floorPlan.id,
                        })}
                      >
                        Refund
                      </Button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="pagination-wrapper">
          <CustomPagination
            page={page}
            setPage={setPage}
            numPages={numPages}
          />
        </div>
      </div>
    </div>
  );
}
