import React, { useEffect, useCallback, useState } from 'react';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { Table, Breadcrumb, Form, Button } from 'react-bootstrap';
import { getLocaleDateString } from 'utils/time';
import { useAppContext } from 'components/AppContext';
import * as api from 'utils/api';
import { useToastContext, Toast } from 'components/Toast';
import ImageStatus from 'components/Common/ImageStatus';
import { getToken } from 'utils/auth';
import config from 'configuration';
import Sidebar from 'components/Common/Sidebar';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import { validateFileExtensions } from 'utils/validation';
import { ImageType, ImageTypeNameMap } from 'constants/common';
import styles from './ImageDetails.module.scss';

export default function ImageDetails() {
  const { imageId: imageIdStr } = useParams();
  const history = useHistory();
  const { request } = useAppContext();
  const { toastError } = useToastContext();
  const [image, setImage] = React.useState(null);
  const { showModal } = useModalContext();
  const [uploading, setUploading] = useState(false);
  const [filesToReplace, setFilesToReplace] = useState({
    frame: null,
    project: null,
  });

  const imageId = parseInt(imageIdStr);

  // const { result: {
  //   logs,
  // },
  // trigger: fetchLogs } = useFetch(useCallback(() => api.getImageLogs(imageId), [imageId]), {
  //   logs: [],
  // });

  const fetchData = useCallback(async () => {
    const { success, result, error } = await request(api.getImage(imageId));

    if (success) {
      setImage(result);
    } else {
      toastError(error);
    }
  }, [imageId, request, toastError]);

  const uploadBlenderFile = async () => {
    setUploading(true);

    const { success, error } = await request(api.uploadFrame(image.id, filesToReplace.project, filesToReplace.frame));

    if (success) {
      fetchData();
    } else {
      toastError(error);
    }

    setUploading(false);
    setFilesToReplace({
      project: null,
      frame: null,
    });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (!image) {
    return null;
  }

  const renderProjectFile = () => {
    if (uploading) {
      return 'Uploading...';
    }

    return image.project ? (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 16,
        }}
      >
        <a
          href={`${image.project}?accessToken=${getToken()}`}
          target="__blank"
        >
          Download
        </a>
        <Button
          style={{
            minHeight: 38,
          }}
          variant="light"
          onClick={() => setImage({
            ...image,
            project: null,
          })}
        >
          Change
        </Button>
      </div>
    ) : (
      <>
        <Form.Group>
          <Form.Label>Blender file</Form.Label>
          <Form.Control
            style={{
              maxWidth: 400,
            }}
            type="file"
            accept=".blend"
            disabled={uploading}
            onChange={(e) => {
              if (validateFileExtensions(e.target.files[0], ['blend'])) {
                setFilesToReplace(f => ({
                  ...f,
                  project: e.target.files[0],
                }));
              } else {
                toastError('Invalid file type');
                e.target.value = '';
              }
            }}
          />
        </Form.Group>
        <Form.Group style={{
          marginTop: 16,
        }}
        >
          <Form.Label>Glb file</Form.Label>
          <Form.Control
            style={{
              maxWidth: 400,
            }}
            type="file"
            accept=".glb"
            disabled={uploading}
            onChange={(e) => {
              if (validateFileExtensions(e.target.files[0], ['glb'])) {
                setFilesToReplace(f => ({
                  ...f,
                  frame: e.target.files[0],
                }));
              } else {
                toastError('Invalid file type');
                e.target.value = '';
              }
            }}
          />
        </Form.Group>
        <Button
          disabled={!filesToReplace.project}
          style={{
            marginTop: 16,
            minHeight: 38,
          }}
          variant="light"
          onClick={() => {
            uploadBlenderFile();
          }}
        >
          Upload
        </Button>
      </>
    );
  };

  return (
    <div className="main image-details">
      <Sidebar />
      <div className="right-part">
        <Toast />
        <div className="title">
          <Breadcrumb>
            <Breadcrumb.Item
              active={false}
              onClick={() => {
                history.push('/images');
              }}
            >
              Images
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {image.id}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="row">
          <div className="col-lg-8">
            <h5>
              Details
            </h5>
            <Table>
              <tbody>
                <tr>
                  <td>
                    ID
                  </td>
                  <td>
                    {image.id}
                  </td>
                </tr>
                <tr>
                  <td>
                    Name
                  </td>
                  <td>
                    {image.name}
                  </td>
                </tr>
                <tr>
                  <td>
                    Type
                  </td>
                  <td>
                    {ImageTypeNameMap[image.type]}
                  </td>
                </tr>
                {image.location && (
                  <tr>
                    <td>
                      Location
                    </td>
                    <td>
                      {image.location.name}
                    </td>
                  </tr>
                )}

                <tr>
                  <td>
                    User ID
                  </td>
                  <td>
                    {image.userId}
                  </td>
                </tr>
                <tr>
                  <td>
                    Status
                  </td>
                  <td>
                    <ImageStatus status={image.status} />
                  </td>
                </tr>
                {image.type !== ImageType.FLOOR_PLAN && image.metadata?.refundReason && (
                  <tr>
                    <td>
                      Refund reason
                    </td>
                    <td>
                      {image.metadata.refundReason}
                    </td>
                  </tr>
                )}
                {image.metadata?.error && (
                  <tr>
                    <td>
                      Error
                    </td>
                    <td>
                      <div style={{
                        whiteSpace: 'pre-wrap',
                      }}
                      >
                        {image.metadata?.error}
                      </div>
                    </td>
                  </tr>
                )}
                {image.background && (
                  <tr>
                    <td>Background</td>
                    <td>
                      <div className="preview-and-download">
                        <img src={image.thumbnail} alt="background" />
                        <a
                          href={`${image.background}?accessToken=${getToken()}`}
                          target="__blank"
                        >
                          Download
                        </a>
                      </div>
                    </td>
                  </tr>
                )}

                <tr>
                  <td>Blender file</td>
                  <td>
                    {renderProjectFile()}
                  </td>
                </tr>
                <tr>
                  <td>Frame</td>
                  <td>
                    {image.frame && (
                    <a
                      href={`${image.frame}?accessToken=${getToken()}`}
                      target="__blank"
                    >
                      Download
                    </a>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    Created At
                  </td>
                  <td>
                    {moment.utc(image.createdAt).local().format(`${getLocaleDateString()} HH:mm`)}
                  </td>
                </tr>
                <tr>
                  <td>
                    Updated At
                  </td>
                  <td>
                    {moment.utc(image.updatedAt).local().format(`${getLocaleDateString()} HH:mm`)}
                  </td>
                </tr>
                {image.type !== ImageType.FLOOR_PLAN && (
                  <tr>
                    <td>Actions</td>
                    <td className={styles.actionButtons}>
                      {image.status !== 'refunded' && (
                      <Button
                        variant="danger"
                        onClick={() => showModal(ModalKey.REFUND, {
                          onDone: fetchData,
                          imageId: image.id,
                        })}
                      >
                        Refund
                      </Button>
                      )}
                    </td>
                  </tr>
                )}

              </tbody>
            </Table>
          </div>
        </div>

        <h5>
          Progresses
        </h5>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Type</th>
              <th>Status</th>
              <th>
                Start At
              </th>
              <th>
                Done At
              </th>
              <th>
                Data
              </th>
            </tr>
          </thead>
          <tbody>
            {image.progresses.map(progress => (
              <tr key={progress.id}>
                <td>{progress.id}</td>
                <td>{progress.type}</td>
                <td>{progress.status}</td>
                <td>
                  {moment.utc(progress.createdAt).local().format(`${getLocaleDateString()} HH:mm`)}
                </td>
                <td>
                  {progress.doneAt && moment.utc(progress.doneAt).local().format(`${getLocaleDateString()} HH:mm`)}
                </td>
                <td>
                  {progress.type === 'render' && (
                    <div className="links">
                      {progress.thumbnailFileId && (
                        <div>
                          <img
                            className="output"
                            src={`${config.apiUrl}/files/${progress.thumbnailFileId}?accessToken=${getToken()}`}
                            alt="Output"
                            style={{
                              maxHeight: '200px',
                            }}
                          />
                          <a
                            href={`${config.apiUrl}/files/${progress.metadata.outputFileId}?accessToken=${getToken()}`}
                            target="__blank"
                          >
                            Download output
                          </a>
                        </div>
                      )}
                      {progress.metadata?.furnitureOutputFileId && (
                        <a
                          href={`${config.apiUrl}/files/${progress.metadata.furnitureOutputFileId}?accessToken=${getToken()}`}
                          target="__blank"
                        >
                          Download furniture layer
                        </a>
                      )}
                      {progress.metadata?.logFileFileId && (
                        <a
                          href={`${config.apiUrl}/files/${progress.metadata.logFileFileId}?accessToken=${getToken()}`}
                          target="__blank"
                        >
                          Download logs
                        </a>
                      )}
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          showModal(ModalKey.IMAGE_FURNITURE_ITEMS, {
                            objects: progress.metadata.objects,
                          });
                        }}
                      >
                        Furniture Items
                      </a>
                    </div>
                  )}

                  {progress.type === 'refund' && (
                    <>
                      <div>
                        Coins:
                        {' '}
                        {progress.metadata.quantity}
                      </div>
                      <div>
                        Reason:
                        {' '}
                        {progress.metadata.reason}
                      </div>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
