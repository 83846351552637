export const STORAGE_PREFIX = 'undefined';

export const TenantType = {
  ROOT: 1,
  AGENCY: 2,
};

export const TenantStatus = {
  ACTIVATED: 1,
  CLOSED: 2,
};

export const ITEMS_PER_PAGE = 10;

export const ImageType = {
  NORMAL: 'normal',
  PANO: 'pano',
  FLOOR_PLAN: 'floorplan',
};

export const ImageTypeNameMap = {
  [ImageType.NORMAL]: '2D',
  [ImageType.PANO]: '360°',
  [ImageType.FLOOR_PLAN]: 'Floor plan',
};

export const FloorPlanFileSource = {
  USER_UPLOAD: 'user_upload',
  CAD: 'cad',
  VIDEO: 'video',
};

export const FloorPlanFileSourceName = {
  [FloorPlanFileSource.USER_UPLOAD]: 'User uploads',
  [FloorPlanFileSource.VIDEO]: 'Video',
  [FloorPlanFileSource.CAD]: 'CAD',
};

export const FloorPlanStatus = {
  UPLOADING: 'uploading',
  CREATED: 'created',
  READY: 'ready',
  REFUNDED: 'refunded',
  FAILED: 'failed',
  EXTRA_COST_APPROVAL: 'extra_cost_approval',
  REQUEST_REFUND: 'request_refund',
  REQUEST_FILES: 'request_files',
};
