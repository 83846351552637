import { FloorPlanStatus } from 'constants/common';
import React from 'react';
import { Badge } from 'react-bootstrap';

export default function ImageStatus({ status }) {
  return (
    <>
      {status === FloorPlanStatus.CREATED && (
        <Badge bg="warning">Created</Badge>
      )}
      {status === FloorPlanStatus.READY && (
        <Badge bg="success">Ready</Badge>
      )}
      {status === FloorPlanStatus.FAILED && (
        <Badge bg="danger">Failed</Badge>
      )}
      {status === FloorPlanStatus.REFUNDED && (
        <Badge bg="secondary">Refunded</Badge>
      )}
      {status === FloorPlanStatus.EXTRA_COST_APPROVAL && (
      <Badge bg="warning">Extra Cost Approval</Badge>
      )}
      {status === FloorPlanStatus.REQUEST_REFUND && (
      <Badge bg="danger">Refund requested</Badge>
      )}
      {status === FloorPlanStatus.REQUEST_FILES && (
      <Badge bg="warning">Files requested</Badge>
      )}
    </>
  );
}
