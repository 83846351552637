import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import styles from './FloorPlanDetails.module.scss';

export default function DesignerNotes({
  designerNotes,
  onSave,
}) {
  const [value, setValue] = useState(designerNotes);
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    setValue(designerNotes);
    setDirty(false);
  }, [designerNotes]);

  return (
    <div style={{
      marginBottom: 16,
    }}
    >
      <Form.Group className="form-group">
        <Form.Control
          as="textarea"
          rows={5}
          value={value}
          onChange={e => {
            setValue(e.target.value);
            setDirty(true);
          }}
        />
        <div className={styles.buttons}>
          <Button
            variant="secondary"
            onClick={() => onSave(value)}
            disabled={!dirty}
          >
            Save
          </Button>
        </div>
      </Form.Group>
    </div>
  );
}
