export const ModalKey = {
  DELETE_CONFIRMATION: 'DELETE_CONFIRMATION',
  EDIT_ADMIN: 'EDIT_ADMIN',
  EDIT_DESIGNER: 'EDIT_DESIGNER',
  EDIT_USER: 'EDIT_USER',
  TRANSACTION_DETAILS: 'TRANSACTION_DETAILS',
  EDIT_ROOM: 'EDIT_ROOM',
  EDIT_CATEGORY: 'EDIT_CATEGORY',
  EDIT_ITEM: 'EDIT_ITEM',
  IMAGE_FURNITURE_ITEMS: 'IMAGE_FURNITURE_ITEMS',
  ADD_ITEMS_TO_CATEGORY: 'ADD_ITEMS_TO_CATEGORY',
  REFUND: 'REFUND',
  EDIT_TENANT_INFO: 'EDIT_TENANT_INFO',
  EDIT_TENANT_STATUS: 'EDIT_TENANT_STATUS',
  TOP_UP_TENANT: 'TOP_UP_TENANT',
  CHARGE_ADDITIONAL_COST: 'CHARGE_ADDITIONAL_COST',
  REFUND_FLOOR_PLAN: 'REFUND_FLOOR_PLAN',
  FILE_REQUEST: 'FILE_REQUEST',
};
