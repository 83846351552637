import { useToastContext, Toast } from 'components/Toast';
import { useAppContext } from 'components/AppContext';
import * as api from 'utils/api';
import { Modal, Button, Form } from 'react-bootstrap';
import React from 'react';
import { useForm } from 'react-hook-form';

export default function ChargeAdditionalCost({
  onClose,
  onDone,
  floorPlanId,
}) {
  const { request } = useAppContext();
  const { toastError } = useToastContext();

  const { handleSubmit, register, formState: {
    errors,
    isSubmitting,
  } } = useForm({
    defaultValues: {
      coins: 0,
      reason: '',
    },
  });

  const onClickCharge = () => {
    handleSubmit(async (values) => {
      const { success, error } = await request(api.chargeAdditionalCost(floorPlanId, {
        reason: values.reason.trim(),
        coins: parseFloat(values.coins),
      }));

      if (!success) {
        toastError(error);
      } else {
        onDone();
        onClose();
      }
    })();
  };

  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      id="modal-charge-addtional-cost"
      size="md"
    >
      <Toast onModal />
      <Modal.Header closeButton>Charge additional cost</Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Coins</Form.Label>
          <Form.Control
            {...register('coins', {
              validate: (value) => {
                const parsedValue = parseFloat(value);
                if (Number.isNaN(parsedValue)) {
                  return 'Invalid value.';
                }

                if (parsedValue <= 0) {
                  return 'Value must be greater than 0';
                }

                return true;
              },
            })}
            type="number"
            step={0.5}
            isInvalid={errors.coins}
          />
          {errors.coins && <Form.Control.Feedback type="invalid">{errors.coins.message}</Form.Control.Feedback>}
        </Form.Group>
        <Form.Group>
          <Form.Label>Reason</Form.Label>
          <Form.Control
            {...register('reason', {
              validate: (value) => {
                if (!value.trim()) {
                  return 'Reason is required.';
                }

                if (value.trim().length > 1000) {
                  return 'Reason cannot be more than 1000 characters.';
                }

                return true;
              },
            })}
            as="textarea"
            isInvalid={errors.reason}
          />
          {errors.reason && <Form.Control.Feedback type="invalid">{errors.reason.message}</Form.Control.Feedback>}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClickCharge} disabled={isSubmitting}>Charge</Button>
      </Modal.Footer>
    </Modal>
  );
}
