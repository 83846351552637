import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useAppContext } from 'components/AppContext';
import { useHistory, useLocation } from 'react-router';

export default function Sidebar() {
  const { user, logout, isRootTenant } = useAppContext();
  const location = useLocation();
  const history = useHistory();
  const path = location.pathname;
  const isActiveItem = (item) => path.startsWith(item.path);

  const NAV_ITEMS = useMemo(() => [
    user.level === 0 && isRootTenant && {
      key: 'tenants',
      path: '/tenants',
      title: 'Tenants',
      onClick: () => {
        history.push('/tenants');
      },
    },
    user.level === 0 && {
      key: 'admins',
      path: '/admins',
      title: 'Admins',
      onClick: () => {
        history.push('/admins');
      },
    },
    isRootTenant && {
      key: 'designers',
      path: '/designers',
      title: 'Designers',
      onClick: () => {
        history.push('/designers');
      },
    },
    {
      key: 'users',
      path: '/users',
      title: 'Users',
      onClick: () => {
        history.push('/users');
      },
    },
    {
      key: 'images',
      path: '/images',
      title: 'Images',
      onClick: () => {
        history.push('/images');
      },
    },
    {
      key: 'floor-plans',
      path: '/floor-plans',
      title: 'Floor Plans',
      onClick: () => {
        history.push('/floor-plans');
      },
    },
    isRootTenant && {
      key: 'render-progresses',
      path: '/render-progresses',
      title: 'Render Progresses',
      onClick: () => {
        history.push('/render-progresses');
      },
    },
    {
      key: 'transactions',
      path: '/transactions',
      title: 'Transactions',
      onClick: () => {
        history.push('/transactions');
      },
    },
    isRootTenant && {
      key: 'rooms',
      path: '/rooms',
      title: 'Rooms',
      onClick: () => {
        history.push('/rooms');
      },
    },
    isRootTenant && {
      key: 'categories',
      path: '/categories',
      title: 'Categories',
      onClick: () => {
        history.push('/categories');
      },
    },
    isRootTenant && {
      key: 'items',
      path: '/items',
      title: 'Items',
      onClick: () => {
        history.push('/items');
      },
    },
    {
      key: 'configs',
      path: '/configs',
      title: 'Configs',
      onClick: () => {
        history.push('/configs');
      },
    },
  ], [history, isRootTenant, user.level]);

  return (
    <div className="sidebar">
      <div className="header">
        {user.name}
        {!isRootTenant && (
          <div className="coins">
            <span>
              Coins:
            </span>
            {' '}
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              {user.tenant.coinsAvailable || 0}
            </span>
          </div>
        )}
        <div
          className="logout"
          onClick={logout}
        >
          Logout
        </div>
      </div>
      <div className="content">
        <div className="navs">
          {NAV_ITEMS.filter(value => value).map((item) => (
            <div
              key={item.key}
              className={classNames(
                'nav',
                isActiveItem(item) && 'active',
              )}
              onClick={item.onClick}
            >
              {item.title}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
